html,
body {
  padding: 0;
  margin: 0;
 
}

a {
  color: #7dd3fc;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body {
  color: #fff;
  background: #363331;
}
